import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Header from 'components/Header'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import styled from 'styled-components'

import { media } from 'styles/media'
import { greyLight, greyDark } from 'styles/colors'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import List from 'components/List'
import PhotoGallery from 'components/PhotoGallery'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import BookButton from 'components/button/BookButton'
import Button from 'components/button/Button'
import SmallCaps from 'components/SmallCaps'
import Icon from 'components/Icon'

const Container = styled.div`
  position: static;
`

const Main = styled.main`
  position: static;
  max-width: 700px;

  small {
    display: block;
    color: ${greyLight};
  }

  ${media.medium`
    padding-right: ${spacing.xLarge};
    max-width: none;
  `};

  ${media.large`
    padding-right: ${spacing.xxxLarge};
  `};

  ${media.xLarge`
    padding-right: 88px;
  `};
`

const Meta = styled.div`
  margin-bottom: ${spacing.large};

  ${media.xLarge`
    margin-bottom: ${spacing.xLarge};
  `};
`

const MetaItemLabel = styled(SmallCaps)`
  color: ${greyDark};
`

const MetaItemIconWrap = styled.div`
  display: flex;
  margin-right: ${spacing.small};
  color: ${greyLight};
`

const MetaItemContainer = styled.div`
  display: inline-flex;
  align-items: center;

  margin-right: ${spacing.medium};

  ${media.medium`
      margin-right: ${spacing.large};
    `};

  ${media.xLarge`
      margin-right: ${spacing.xLarge};
    `};
`

const findAmenityId = (value) => {
  switch (value) {
    case 'Two bedrooms':
      return 'amenity.twoBedrooms'
    case 'Two bathrooms':
      return 'amenity.towBathroom'
    case 'Separate living and bedroom areas':
      return 'amenity.separateLivingBedrooAreas'
    case 'Private balcony with direct access and view of Griffintown and the canal':
      return 'amenity.privateBalconyViewGriffintownCanal'
    case 'Private balcony with direct access and a view of the canal and the city':
      return 'amenity.privateBalconyViewCanalCity'
    case 'Private balcony with direct access':
      return 'amenity.privateBalconyDirectAccess'
    case 'High quality bedding':
      return 'amenity.qualityBedding'
    case 'Free high speed internet':
      return 'amenity.freeInternet'
    case "43 '' HD Smart Flat Panel TV":
      return 'amenity.smartFlatTv'
    case 'Dishes and utensils':
      return 'amenity.dishesUtensils'
    case 'Nespresso coffee machine':
      return 'amenity.nespressoCoffeeMachine'
    case 'High-end household appliances':
      return 'amenity.householdAppliances'
    case 'Ironing board':
      return 'amenity.ironingBoard'
    case 'IPod / iPhone socket':
      return 'amenity.ophoneSocket'
    case 'All our rooms and balconies are non-smoking':
      return 'amenity.noSmoking'
    case 'Garage available on request as an option':
      return 'amenity.garageAvailableOption'
    case 'Over 50 TV channels & Netflix':
      return 'amenity.50TvChannels'
  }
}

const bedTypeReducer = (value) => {
  switch (value) {
    case 'Sofa bed':
      return 'bedType.sofaBed'
    case 'Double bed':
      return 'bedType.doubleBed'
    case 'Queen bed':
      return 'bedType.queenBed'
    case 'King bed':
      return 'bedType.kingBed'
  }
}

const MetaItem = ({ icon, label }) => (
  <MetaItemContainer>
    <MetaItemIconWrap>
      <Icon icon={icon} size='small' />
    </MetaItemIconWrap>
    <MetaItemLabel>{label}</MetaItemLabel>
  </MetaItemContainer>
)

const OneBedroom = ({ data }) => {
  const intl = useIntl()

  const {
    prismic: { apartment },
  } = data

  let pageAlternativeLangPath

  if ((apartment?._meta.alternateLanguages || []).length > 0) {
    pageAlternativeLangPath = `/apartments/${apartment._meta.alternateLanguages[0].uid}`
  }

  const photos = (apartment?.gallery || []).filter((picture) => {
    return picture.square && picture.wide
  })

  const bookingLink = apartment?.booking_link?.url || undefined
  const bookingLinkTarget = apartment?.booking_link?.target || undefined

  const occupancyString = (apartment?.occupancy || [])
    .map(({ adult, child }) => {
      let res = ''

      res += intl.formatMessage(
        { id: 'occupancyCount' },
        {
          adultCount: Number(adult),
          childCount: Number(child),
        }
      )

      return res
    })
    .join(` ${intl.formatMessage({ id: 'or' })} `)

  return (
    <Layout>
      <MetaTags
        title={`${apartment?.seo_title} | ${intl.formatMessage({
          id: 'apartment',
        })} | Hotel Griffintown`}
        description={apartment?.seo_description}
      />

      <Header
        eyebrow={intl.formatMessage({ id: 'apartment' })}
        heading={apartment?.title}
        fluid={apartment?.featured_pictureSharp?.childImageSharp?.fluid}
        pageAlternativeLangPath={pageAlternativeLangPath}
      />

      <Container>
        <Section>
          <Wrapper size='narrow'>
            <GridRow>
              <GridItem xs='1-1' md='2-3'>
                <Main>
                  <Heading size={4} variant='sans'>
                    {intl.formatMessage({ id: 'suites.overview' })}
                  </Heading>
                  <Meta>
                    {(apartment?.beds || []).map((bed) => (
                      <MetaItem
                        icon='bed'
                        label={`${bed.quantity} ${intl.formatMessage({
                          id: bedTypeReducer(bed.type_of_bed),
                        })}`}
                      />
                    ))}

                    {apartment?.size && (
                      <MetaItem
                        icon='size'
                        label={`${apartment.size} ${intl.formatMessage({
                          id: 'sqft',
                        })}`}
                      />
                    )}

                    {occupancyString && (
                      <MetaItem icon='occupants' label={occupancyString} />
                    )}
                  </Meta>

                  <>{RichText.render(apartment?.description)}</>

                  <BookButton url={bookingLink} target={bookingLinkTarget} />
                </Main>
              </GridItem>
              <GridItem xs='1-1' md='1-3'>
                <List
                  items={(apartment?.amenities || []).map(({ amenity }) =>
                    intl.formatMessage({ id: findAmenityId(amenity) })
                  )}
                />
              </GridItem>
            </GridRow>
          </Wrapper>
        </Section>
        <Section flushTop>
          <Wrapper>
            {photos && (
              <SectionBlock>
                <PhotoGallery photos={photos} />
              </SectionBlock>
            )}
            <SectionBlock>
              <div
                css={`
                  text-align: center;
                `}
              >
                <Button to='/apartments' theme='secondary'>
                  {intl.formatMessage({ id: 'home.suites.explore' })}
                </Button>
              </div>
            </SectionBlock>
          </Wrapper>
        </Section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query apartmentQuery($language: String!, $uid: String!) {
    prismic {
      apartment(lang: $language, uid: $uid) {
        title
        short_description
        description
        size
        featured_picture
        featured_pictureSharp(crop: "headerWide") {
          childImageSharp {
            fluid(maxWidth: 2560, maxHeight: 590) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seo_title
        seo_description
        gallery {
          picture
          wide: pictureSharp(crop: "wide") {
            id
            childImageSharp {
              fluid(maxWidth: 1348, maxHeight: 540) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          square: pictureSharp(crop: "square") {
            id
            childImageSharp {
              fluid(maxWidth: 658, maxHeight: 658) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        amenities {
          amenity
        }
        beds {
          quantity
          type_of_bed
        }
        occupancy {
          adult
          child
        }
        booking_link {
          ... on PRISMIC__ExternalLink {
            target
            _linkType
            url
          }
        }
        _meta {
          alternateLanguages {
            lang
            uid
          }
        }
      }
    }
  }
`

export default OneBedroom
